import React, { useEffect } from "react"

import Icon from "../components/Icon"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import SEO from "../components/SEO"
import ImageGallery from "../components/ImageGallery"

import "./brochure.scss"

const MidCityBrochure = ({ pageContext }) => {
  const { vehicle } = pageContext
  const dealership = "midcity"

  useEffect(() => {
    setTimeout(() => window.print(), 500)
  })

  return (
    <>
      <div className="row heading-spacer">
        <div className="col-12"></div>
      </div>
      <SEO title={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} />
      <Heading title={`${vehicle.year} ${vehicle.make} ${vehicle.model}`} />
      <SubHeading subHeading={`${vehicle.derivative}`} />
      <div className="row">
        <div className="col-12 col-md-6">
          <ImageGallery
            images={vehicle.images ? vehicle.images.slice(0, 7) : null}
            dealership={dealership}
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 text-center">
              <p className="vehicle-price">{`$${vehicle.egcPrice.toLocaleString()}`}</p>
            </div>
          </div>
          <div className="row vehicle-description-item-first">
            <div className="col-4">
              <span>
                <b>Colour:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.colour}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Body:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.body}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Transmission:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.transmission}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Engine Size:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.engine}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Fuel Type:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.fuel}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Kilometres:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.kilometres.toLocaleString()}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Registration:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.rego}</span>
            </div>
          </div>
          <div className="row vehicle-description-item">
            <div className="col-4">
              <span>
                <b>Stock Number:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.stockNumber}</span>
            </div>
          </div>
          <div className="row vehicle-description-item-last">
            <div className="col-4">
              <span>
                <b>VIN:</b>
              </span>
            </div>
            <div className="col-8">
              <span>{vehicle.vin}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <p className="detail">Vehicle Description:</p>
              <p>{vehicle.sellingPoints}</p>
            </div>
            <div className="col-12 text-center">
              <p className="detail">Phone:</p>
              <p className="detail-value">(02) 4421 3666</p>
            </div>
            <div className="col-12 text-center">
              <p className="detail">Location:</p>
              <p className="detail-value">
                Cnr Princes Hwy &amp; North Street, Nowra
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 vehicle-selling-points-container">
          <p className="vehicle-selling-points-title">Vehicle Features:</p>
          <div className="row">{buildOptionsList(vehicle.options)}</div>
        </div>
      </div>
    </>
  )
}

const buildOptionsList = (vehicleOptions) => {
  const vehicleOptionsArray = vehicleOptions.split(", ")
  const vehicleOptionsList = vehicleOptionsArray.map((vehicleOption, index) => (
    <div className="col-12 col-sm-6 col-lg-4 vehicle-option" key={index}>
      <Icon prefix="fas" name="check-square" /> {vehicleOption}
    </div>
  ))
  return vehicleOptionsList
}

export default MidCityBrochure
